import useWidgetStyles from "components/utils/useWidgetStyles";
import { useCallback } from "react";
import ButtonComponent from "react-bootstrap/Button";
import { useHistory } from "react-router-dom";
import { ButtonProps } from "types/ButtonProps";
import cn from "classnames";
import { handleLinkClick } from "components/utils/links";

export function Button(props: ButtonProps) {
    const history = useHistory();
    const { linkType, emailSubject, emailBody, secondaryButtonType, ...commonProps } = props;

    let href = props.href;
    if (linkType === "email") {
        href = `mailto:${props.href}`;

        if (emailSubject && emailBody) {
            href = `${href}?subject=${emailSubject}&body=${emailBody}`;
        } else if (emailSubject) {
            href = `${href}?subject=${emailSubject}`;
        } else if (emailBody) {
            href = `${href}?body=${emailBody}`;
        }
    }

    const style = useWidgetStyles({
        ...(props ?? {}),
        borderColor: props?.backgroundColor,
        width: props?.buttonWidth === "fullWidth" ? "100%" : undefined,
    });

    let className = props.className;
    if (props?.variant === "secondary" && props?.secondaryButtonType) {
        className = cn(className, props.secondaryButtonType);
    }

    const onClick = useCallback(
        (event: React.MouseEvent<HTMLButtonElement>) => {
            if (props.onClick) {
                return props.onClick(event);
            }

            handleLinkClick(event, href, props.target, history);
        },
        [history, props, href]
    );

    return (
        <ButtonComponent {...commonProps} href={href} className={className} style={style} onClick={onClick}>
            {props.text ?? props.children}
        </ButtonComponent>
    );
}
